@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Disable the tap highlight effect for the entire app */
  * {
    -webkit-tap-highlight-color: transparent; /* For older versions of iOS */
    tap-highlight-color: transparent; /* For newer versions of iOS */
  }
}
